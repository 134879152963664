import { GridRowParams } from '@material-ui/data-grid';
import React, { ChangeEvent, useCallback, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import Search from '~/components/Search';
import { getUserData } from '~/services/user';
import ToggleDefault from '~/components/ToggleDefault';
import { Container } from './styles';
import api from '~/services/api';
import FormDefault from '~/components/FormDefault';
import Loja from '~/components/Loja';
import {
  BuscaParceiro,
  InputAsyncSelect,
  InputNumber,
  InputNumber2,
  InputText,
} from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';
import Separator from '~/components/Separator';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaOperador } from './validations/validation';

const MySwal = withReactContent(Swal);

const Operador: React.FC = () => {
  const {
    register,
    control,
    reset,
    getValues,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaOperador),
  });

  const user = getUserData();
  /**
   * Adicionar tipagem a todos os objetos da tela
   */
  const [loader, setLoader] = useState<boolean>(false);
  const [inativo, setInativo] = useState<boolean>(false);
  const [isInvalidLoja, setIsInvalidLoja] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [manterDados, setManterDados] = useState<boolean>(false);
  const [lojas, setLojas] = useState<number | number[]>(user.loja);

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = useCallback(() => {
    setUpdate(false);
    setLojas(user.loja);
    setIsInvalidLoja(false);
    clearErrors();
    reset({
      cod_operador: '',
      des_operador: '',
      busca_parceiro: '',
      finalizadora: '',
    });

    setInativo(false);
  }, [user.loja]);

  /**
   * Evento disparado ao clicar em uma linha da tabela
   * @param param
   */
  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const {
      cod_finalizadora,
      des_finalizadora_view,
      cod_pessoa,
      des_cliente,
      des_operador,
      cod_operador,
      cod_loja,
      flg_inativo,
      num_cpf_cnpj,
    } = row;
    if (!cod_finalizadora) {
      setValue('finalizadora', '');
    } else {
      setValue('finalizadora', {
        label: des_finalizadora_view,
        cod_finalizadora,
      });
    }
    setValue('busca_parceiro', {
      cod_pessoa,
      value: cod_pessoa,
      label: `${cod_pessoa} - ${des_cliente} (${num_cpf_cnpj})`,
    });
    setValue('des_operador', des_operador);
    setValue('cod_operador', cod_operador);
    setUpdate(true);
    setLojas(cod_loja);

    setInativo(flg_inativo);

    setLoader(false);

    setShowSearch(false);
  };
  /**
   * Evento reponsavel por cadastrar ou editar um Operador
   * @return
   */
  const onSubmit = async () => {
    try {
      if (isUpdate) {
        const { data } = await api.put(
          `/operador/${getValues('cod_operador')}`,
          {
            cod_loja: lojas,
            cod_finalizadora: getValues('finalizadora').cod_finalizadora,
            tipo_operador: 0,
            cod_operador: getValues('cod_operador'),
            flg_inativo: inativo,
            cod_pessoa: getValues('busca_parceiro').cod_pessoa,
            des_operador: getValues('des_operador').trim(),
          },
        );

        if (data.success) {
          toast.success(data.message);
          resetFormData();
          setShowSearch(true);
        }

        setLoader(false);

        return;
      }
      const { data } = await api.post('/operador', {
        cod_loja: lojas,
        cod_finalizadora: getValues('finalizadora').cod_finalizadora,
        tipo_operador: 0,
        cod_operador: getValues('cod_operador'),
        flg_inativo: inativo,
        cod_pessoa: getValues('busca_parceiro').cod_pessoa,
        des_operador: getValues('des_operador').trim(),
      });

      if (data.success) {
        toast.success(data.message);
        resetFormData();
      }
    } finally {
      setLoader(false);
    }
  };

  /**
   * Inativa um caixa
   */

  const handleSwitch = useCallback(() => {
    setManterDados(!manterDados);
  }, [manterDados]);

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={14}
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={14}
          title="Cadastro de Operador de Caixa"
          onSave={handleSubmit(onSubmit)}
          codigoRegistro={[
            {
              value: getValues('cod_operador'),
              des_campo: 'Código',
            },
          ]}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isDelete={false}
          isUpdate={isUpdate}
          onNew={resetFormData}
          onDelete={() => []}
          onClearFields={resetFormData}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          onSwitch={handleSwitch}
        >
          <div className="row">
            <div
              className="col-sm-12 col-md-12"
              style={{ zIndex: 3, marginTop: '10px' }}
            >
              <Loja
                selectedLoja={lojas}
                onChange={(val) => {
                  setIsInvalidLoja(false);
                  !isUpdate && setValue('finalizadora', null);
                  setLojas(val);
                }}
                isDisabled={isUpdate}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-2">
              <InputNumber2
                name="cod_operador"
                label="Código"
                disabled={isUpdate}
                placeholder="0"
                register={register}
                control={control}
                isError={!!errors.cod_operador}
                maxLength={12}
                max={9999}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length < 12) {
                    clearErrors('cod_operador');
                    setValue('cod_operador', Math.abs(Number(e.target.value)));
                  }
                }}
              />
            </div>
            <div className="col-md-12 col-lg-8 col-xl-9">
              <InputText
                name="des_operador"
                label="Descrição Tesouraria"
                placeholder="Informe a Descrição"
                register={register}
                control={control}
                isError={!!errors.des_operador}
                maxLength={25}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value.trimStart();
                  clearErrors('des_operador');
                  setValue('des_operador', value.toUpperCase());
                }}
              />
            </div>
            <div
              className="col-md-12 col-lg-1 col-xl-1"
              style={{ marginTop: '0.67em' }}
            >
              <div className="toggle">
                <ToggleDefault
                  labelText="Inativo?"
                  setChecked={inativo === true}
                  onSwitch={() => {
                    setInativo(!inativo);
                  }}
                />
              </div>
            </div>
          </div>
          <Separator labelText="Tesouraria" />
          <div className="row">
            <div
              className="col-sm-12 col-md-12 col-lg-9"
              style={{ marginTop: '10px' }}
            >
              <BuscaParceiro
                label="Cliente p/ Quebra"
                placeholder="Selecione..."
                name="busca_parceiro"
                register={register}
                isError={!!errors.busca_parceiro}
                control={control}
                customOptions={{
                  buscarPor: { cliente: true },
                }}
                changeSelected={(selected: any) => {
                  clearErrors('busca_parceiro');
                  setValue('busca_parceiro', selected);
                }}
              />
            </div>
            <div
              className="col-sm-12 col-md-12 col-lg-3"
              style={{ marginTop: '10px' }}
            >
              <InputAsyncSelect
                label="Finalizadora"
                maxLength={50}
                placeholder="Selecione..."
                name="finalizadora"
                register={register}
                isError={!!errors.finalizadora}
                control={control}
                changeSelected={(selected) => {
                  clearErrors('finalizadora');
                  setValue('finalizadora', selected);
                }}
                api={{
                  route: '/finalizadora',
                  method: 'get',
                  bodyParams: {
                    codLoja: lojas,
                  },
                  fields: ['des_finalizadora'],
                  dependsOf: ['codLoja'],
                  messageForDependsOf: 'É necessário selecionar a loja',
                  searchBeforeFilter: true,
                }}
              />
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default Operador;
